import BlockContent from '@sanity/block-content-to-react';
import React from 'react';
import TextLink from './components/ui/TextLink';
import { useCookieSettings } from './contexts/CookieSettingsContext';
import { PageReferenceType } from './graphql-fragments/ButtonLink';
import * as styles from './serializers.module.scss';
import { RawPortableText } from './types/types';
import { getReferenceUrl, SANITY_TYPE_TO_TYPENAME, slugify } from './utils/utils';

export const InternalLinkSerializer = (props: {
  mark: {
    linkToCookieSettings?: boolean;
    reference?: {
      slug: { current: string };
      _type: PageReferenceType;
    };
    anchorLink?: string;
  };
  children: React.ReactNode;
}): React.ReactElement => {
  const { showCookieSettingsModal } = useCookieSettings();

  if (props.mark.linkToCookieSettings) {
    return (
      <a
        className={styles.link}
        onClick={() => {
          showCookieSettingsModal();
        }}
      >
        {props.children}
      </a>
    );
  } else {
    return (
      <TextLink
        to={
          (props.mark.reference
            ? getReferenceUrl(
                SANITY_TYPE_TO_TYPENAME[props.mark.reference._type],
                props.mark.reference.slug.current,
              )
            : '') + (props.mark.anchorLink ? '#' + props.mark.anchorLink.replace('#', '') : '')
        }
      >
        {props.children}
      </TextLink>
    );
  }
};

const ExternalLinkSerializer = (props: {
  mark: {
    url: string;
  };
  children: React.ReactNode;
}): React.ReactElement => {
  return (
    <TextLink openInNewTab to={props.mark.url}>
      {props.children}
    </TextLink>
  );
};

const BlockSerializer = (props: {
  node: {
    children: Array<{
      text: string;
    }>;
    style?: string;
  };
  children: React.ReactNode;
}): React.ReactElement => {
  const slug = slugify(props.node.children.map(child => child.text).join(''));
  if (props.node.style === 'h2') {
    return <h2 id={slug}>{props.children}</h2>;
  }
  if (props.node.style === 'h3') {
    return <h3 id={slug}>{props.children}</h3>;
  }
  if (props.node.style === 'h4') {
    return <h4 id={slug}>{props.children}</h4>;
  }
  if (props.node.style === 'h5') {
    return <h5 id={slug}>{props.children}</h5>;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
  return BlockContent.defaultSerializers.types.block(props);
};

const ListSerializer = (props: {
  type: string;
  level: number;
  children: React.ReactNode;
}): React.ReactElement => {
  if (props.type === 'number' && props.level === 2) {
    return <ol type="a">{props.children}</ol>;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
  return BlockContent.defaultSerializers.list(props);
};

const QuoteSerializer = (props: {
  node: {
    quote: string;
    text: RawPortableText;
  };
}): React.ReactElement => {
  return (
    <div className={styles.quoteContainer}>
      <blockquote className={styles.quote}>{props.node.quote}</blockquote>
      <BlockContent
        className={styles.text}
        blocks={props.node.text}
        renderContainerOnSingleChild
        serializers={serializers}
      />
    </div>
  );
};

const serializers = {
  marks: {
    internalLink: InternalLinkSerializer,
    externalLink: ExternalLinkSerializer,
  },
  types: {
    block: BlockSerializer,
    quote: QuoteSerializer,
  },
  list: ListSerializer,
};

export default serializers;
